import { Login } from '../components/login/login';
import { LoginFirstStep } from '../components/login-first-step/login_fitst_step';
import { LoginStep } from '../api/authentication_state';
import { RootState } from '../../../app/store';
import { useSelector } from 'react-redux';
import React from 'react';
import { Box } from '@mui/material';
// ----------------------------------------------------------------------

const LoginView = () => {
  const loginStep:LoginStep = useSelector((state:RootState) => state.auth.loginStep);
  return (
    <Box display='flex' flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'} minHeight={'100vh'}>
      {
        loginStep === LoginStep.first && <LoginFirstStep/>
      }
      
      {
         loginStep === LoginStep.second && <Login/>
      }
    </Box>
  );
};

export default LoginView;
