import { Box, CircularProgress, Stack } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { AuthType } from "../features/auth/api/authentication_state";
import { Header } from "../components/header/header";


export const DashboardLayout = () => {

  const success = useSelector((root: RootState) => root.auth.success);
  const navigate = useNavigate();

  useEffect(
    () => {
      if (success == AuthType.Fail) {
        navigate('/auth/login')
      }
    }, [success]
  );
  
    if (success == AuthType.Pending) {
      return (
        <Box component='div' overflow={'hidden'} width={'100vw'} height={'100vh'}
          display='flex' alignItems='center' justifyContent='center' alignContent={'center'}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box component='div' overflow={'hidden'} width={'100vw'} height={'100vh'}>
        <Box display='flex' flexDirection='column' height={'100%'}>
            <Header/>
            <Outlet />
        </Box>
      </Box>
    );
  }
