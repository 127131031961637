import { Box, Chip, Popover, Stack, Typography, useTheme } from "@mui/material";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { TicketType } from "../models/response/TicketModel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { OrganizationModel } from "../../../models/response/organization_model";
import { loadOrganizations, selectCurrentOrganization } from "../api/customer_service_action";

export interface ChooseOrganizationsPopOverProps {
    open: boolean;
    anchor: HTMLDivElement | null;
    onClose: () => void;
}

export const ChooseOrganizationsPopOver: React.FC<ChooseOrganizationsPopOverProps> = (props: ChooseOrganizationsPopOverProps) => {

    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const organizations: OrganizationModel[] = useSelector((state: RootState) => state.customerService.organizations);
    const theme = useTheme();
    useEffect(() => {
        dispatch(loadOrganizations({}));
    }, [organizations]);
    
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: {
                        borderRradius: '8px',
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                    },
                }
            }}
            onClose={props.onClose}
        >
            <Stack  direction='column' p={2} spacing={1}
                sx={{ overflowY: 'scroll', maxHeight: '250px' }}>
                    {
                        organizations.map((element) => 
                        <Box sx={{'&:hover':{cursor:'pointer'}}} onClick={() => {
                            dispatch(selectCurrentOrganization(element));
                            props.onClose();
                        }}>
                            <Typography variant="body2" key={element.id} color={theme.palette.textColor?.main}>
                                {element.name}
                            </Typography>
                        </Box>
                    )
                    }
            </Stack>
        </Popover>
    );
}
