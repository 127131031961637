import { Box, Divider, Typography, useTheme } from "@mui/material";

export interface CustomerServiceChatDateProps{
    date: string;
  }

export const CustomerServiceChatDate: React.FC<CustomerServiceChatDateProps> = (props: CustomerServiceChatDateProps) => {
    
    const date = new Date(props.date);
    const theme = useTheme();
    
    const monthNames = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
      ];
    
    return (
        <Box display={'flex'} flexDirection={'row'} alignItems='center' justifyContent={'center'} p={1}>
            <Box flexGrow={1} marginRight={2}>
                <Divider flexItem />
            </Box>
            <Typography color={theme.palette.textColor?.main} variant='body2'>
                {date.getDate()} {monthNames[date.getMonth()]} {date.getFullYear()}
            </Typography>
            <Box flexGrow={1} marginLeft={2}>
                <Divider flexItem />
            </Box>
        </Box>
    );
}