import { Box, CircularProgress, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { ImageDialog } from '../image_dialog/image_dialog';
import { AttachmentModel, FileType } from '../../features/customer_service/models/response/attachment_model';


export interface CardImagesProps {
  className?: string;
  images: AttachmentModel[];
  pendingFiles?: string[];
  isDeleteable: boolean;
  onDelete?: (image: AttachmentModel) => void;
}

export const CardImages: React.FC<CardImagesProps> = (props: CardImagesProps) => {

  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState<boolean>(false);

  const handleDelete = (image: AttachmentModel) => {
      if(props.onDelete) props.onDelete(image)
  };


  return (
    <Stack direction="row" spacing={1} p={1}>
      {
        open && !props.isDeleteable && 
        <ImageDialog 
          images={props.images.filter((image) => image.type === FileType.IMAGE)} 
          open={open} close={() => {
            setOpen(false);
          }}/>
      }
      {props.images.map((image, index) => (
        <Box onClick={image.type === FileType.IMAGE?
          () => {setOpen(true)}:() => {
            if(!props.isDeleteable)
                window.open(image.url, '_blank')
          }}
        key={index} 
        borderRadius={'8px'}
        border={image.type ===FileType.DOCUMENT?1:undefined}
        borderColor={image.type ===FileType.DOCUMENT?'#D9D9D9':undefined}
        sx={{
          display:'flex',
          width:image.type === FileType.DOCUMENT?null:50,
          paddingLeft:image.type === FileType.DOCUMENT?1:null,
          paddingRight:image.type === FileType.DOCUMENT?1:null,
          height: 50,
          overflow: 'hidden',
          position: 'relative',
          backgroundColor:image.type ===FileType.DOCUMENT?'#FFFFFF':undefined,
          color:image.type ===FileType.DOCUMENT?'#5876FF':undefined
        }}>
          <Box style={{ width: '100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {
              image.type === FileType.IMAGE && 
              <img
                src={image.url}
                style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
              />
            }
            {
              image.type === FileType.DOCUMENT && 
              <Typography>{image.name}</Typography>
            }            
          </Box>
          {
            props.isDeleteable &&
            <IconButton onClick={() => handleDelete(image)
            } size='small' style={{
              position: 'absolute',
              top: 1,
              right: 1,
              width: 20,
              height: 20,
              backgroundColor: 'rgb(255, 255, 255)',
            }}>
              <ClearIcon />
            </IconButton>
          }
        </Box>
      ))}
      {
        props.pendingFiles && 
        props.pendingFiles.map((file) => (

          <Box 
          key={file} 
          borderRadius={'8px'}
          border={1}
          borderColor={'#D9D9D9'}
          sx={{
            display:'flex',
            width:50,
            height: 50,
            justifyContent:'center',
            alignItems:'center'
           
          }}>
            <CircularProgress size={20} 
                    thickness={4} />
          </Box>
        ))
      }
    </Stack>
  )
}