import { Box, Popover, Stack, Typography, useTheme } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { MarkAsSolvedTicketModel } from "../models/request/mark_as_solved_ticket_model";
import { markTicketAsSolved } from "../api/customer_service_action";

export interface CustomerServiceChatActionProps{
    onClose: () => void;
    anchor: HTMLDivElement | null;
    open: boolean;
}


export const CustomerServiceChatAction: React.FC<CustomerServiceChatActionProps> = (props: CustomerServiceChatActionProps) => {
    
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentOrganization = useSelector((state: RootState) => state.customerService.currentOrganization);
    const currentTicket = useSelector((state: RootState) => state.customerService.currentTicket);
    const  handleMarkAsSolved = () => {
        if (currentOrganization && currentTicket){
            const data: MarkAsSolvedTicketModel = {
                ticketId: currentTicket.id,
                organizationId: currentOrganization.id
            }
            dispatch(markTicketAsSolved(data));
            props.onClose();
        }
        
    }
    
    return (
        <Popover
                elevation={1}
                onClose={props.onClose}
                slotProps={{
                    paper:{
                        sx: {
                            borderRradius: '8px',
                            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                          },
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                anchorEl={props.anchor}
                open={props.open}>
                    <Stack direction={'column'} spacing={1} p={1}>
                        <Box sx={{'&:hover':{cursor:'pointer'}}} onClick={handleMarkAsSolved}>
                            <Typography>{t('customerServicePage.markAsSolvedAction')}</Typography>    
                        </Box>
                    </Stack>
                </Popover>
    );
}

export const CustomerServiceChatHeader: React.FC = () => {
    
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const currentTicket = useSelector((state:RootState) => state.customerService.currentTicket);
    
    const openTicketActionPopOver = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    }

    const closeTicketActionPopOver = () => {
        setAnchorEl(null);
    }

    if(currentTicket)
        return (
            <Box p={2} display='flex' flexDirection='row' justifyContent={'space-between'}
                sx={{ boxShadow: '0px 4px 2px -4px rgba(0,0,0,0.25) '}}
            >
                <Typography variant='body1' color={theme.palette.textColor?.main} fontWeight={600}>
                    {currentTicket.title}
                </Typography>
                    <Box 
                    onClick={openTicketActionPopOver}
                    color={theme.palette.textColor?.main} sx={{'&:hover':{cursor:'pointer'}}}>
                        <MoreVertIcon/>
                    </Box>
                <CustomerServiceChatAction 
                onClose={closeTicketActionPopOver} 
                anchor={anchorEl} open={anchorEl !== null}/>
            </Box>
        );
    
    return <></>;
}