import { createSlice } from '@reduxjs/toolkit';
import { AuthType, AuthenticationState, LoginStep } from './authentication_state';
import { alreadyLoggedUser, loginUser, logout, resetYourPassword, retrieveTenantConfiguration } from './authAction';
import { UserModel } from '../../../models/response/user_model';

const initialState: AuthenticationState = {
  loading: false,
  loginStep: LoginStep.first,
  user: undefined,
  userToken: null,
  error: null,
  success: AuthType.Pending,

  email: '',
  currentTenant: '',
  
  loginWithPassword: false,
  forgotYourPassword: false,
  
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveTenantConfiguration.fulfilled, (state, payload) => {
        state.loginStep = LoginStep.second;
        state.email = payload.payload.email;
        state.currentTenant = payload.payload.tenantId;
        state.loginWithPassword = payload.payload.loginWithPassword;
        state.loading = false;
        state.error = null;
      })
      .addCase(retrieveTenantConfiguration.pending, (state, payload) => {
        state.loading = true;
      })
      .addCase(retrieveTenantConfiguration.rejected, (state, payload) => {
        state.loading = false;
        const error = payload.payload as string;
        state.error = error;
        if (error.includes('422')){
          state.error = 'Insert a valid email address'
        }
      })
      .addCase(resetYourPassword.fulfilled, (state, payload) => {
        state.forgotYourPassword = true;
      })

      .addCase(loginUser.fulfilled, (state, payload) => {
        state.loading = false;
        state.success = AuthType.Success;
        state.user = payload.payload;
      })
      .addCase(loginUser.pending, (state, payload) => {
        state.loading = true;
        state.success = AuthType.Pending;
      })
      .addCase(loginUser.rejected, (state, payload) => {
        state.loading = false;
        state.success = AuthType.Fail;
        state.error = 'be sure your password is correct';
      })
      .addCase(alreadyLoggedUser.fulfilled, (state, payload) => {
        state.loading = false;
        state.success = AuthType.Success;
        
        state.user = payload.payload;

        if (state.user === undefined){
          state.success = AuthType.Fail;
        }
      })
      .addCase(alreadyLoggedUser.pending, (state, payload) => {
        state.loading = true;
        state.success = AuthType.Pending;
      })
      .addCase(alreadyLoggedUser.rejected, (state, payload) => {
        state.loading = false;
        state.success = AuthType.Fail;
        state.error = payload.error.message || 'Login failed';
      })
      .addCase(logout.fulfilled, (state, payload) => {
        state.user = undefined;
        state.loginStep = LoginStep.first;
        state.success = AuthType.Fail;
        state.forgotYourPassword = false;
      })
  }
});


export default authSlice.reducer;

