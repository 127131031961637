import { OrganizationModel } from "../../../models/response/organization_model";
import { MessageModel, TicketMessageModel } from "../models/response/TicketMessageModel";
import { TicketModel, TicketType } from "../models/response/TicketModel";

export enum TicketSentStatus{
    sent,
    pending,
    error,
    none,
}

export enum TicketStatus {
    open = 'open',
    closed = 'closed',

}

export interface CustomerServiceState{
    organizations: OrganizationModel[];
    currentOrganization: OrganizationModel | undefined;
    tickets: TicketModel[];
    ticketStatus: TicketStatus;
    
    currentTicket: TicketModel | undefined;
    messageDraft: MessageModel | undefined;
    messages: TicketMessageModel[]
}