import { initializeApp } from '@firebase/app';
import { getFirestore } from '@firebase/firestore';
import { ENV_DEV, ENV_PROD } from '../constants';

//TODO move all this informations to a .env file
export const firebaseDevelopmentConfig = {
    apiKey: "AIzaSyCm_i3REIZPriVemQ57NSNoEqJuEL4bqls",
    authDomain: "syllotips-dev.firebaseapp.com",
    projectId: "syllotips-dev",
    storageBucket: "syllotips-dev.appspot.com",
    messagingSenderId: "193300566576",
    appId: "1:193300566576:web:e2ac7fa6d4f308ee4413f5",
    measurementId: "G-EX9M6ZF03T"
  };

  export const firebaseProductionConfig = {
    apiKey: "AIzaSyDoEr4-whyMQXbEGGUcu9rRbr4bED0C5dk",
    authDomain: "syllotips-prod.firebaseapp.com",
    projectId: "syllotips-prod",
    storageBucket: "syllotips-prod.appspot.com",
    messagingSenderId: "138838983723",
    appId: "1:138838983723:web:f92d1388e7c962342a166e",
    measurementId: "G-NNX8PZQC4R"
  };

let currentConfig = {};
switch (process.env.REACT_APP_ENV) {
  case ENV_DEV:
    currentConfig = firebaseDevelopmentConfig;
    break;
  case ENV_PROD:
    currentConfig = firebaseProductionConfig;
    break;
  default:
    throw new Error('Invalid environment');
};


export const firebaseApp = initializeApp(currentConfig);
//setLogLevel('debug');

export const firestore =  getFirestore();

