import { Navigate, useRoutes } from "react-router-dom";
import { PATH_DASHBOARD } from "./path";
import AuthLayout from "../layout/auth_layout";
import LoginView from "../features/auth/view/login";
import { DashboardLayout } from "../layout/dashboard_layout";
import CustomerServicePage from "../features/customer_service/view/customer_service_page";
export default function Router() {

    return useRoutes([
        {
          path: "/auth",
          element: <AuthLayout />,
          children: [
            { path: "login", element: <LoginView /> },
          ],
        },
        {
          path: "/",
          element: <DashboardLayout />,
          children: [
            {element: <Navigate to={PATH_DASHBOARD.general.app} replace />, index: true },
            {path: 'home', element: <CustomerServicePage/>},  
            
          ]
        },    
    ]);
  }