import React, {  useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {  AppDispatch, RootState } from '../../../../app/store';
import { loginUser, retrieveTenantConfiguration } from '../../api/authAction';
import { Box, Button, CircularProgress, Container, CssBaseline, Grid, Link, TextField, ThemeProvider, Typography, createTheme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TenantRequestModel } from '../../models/request/login_model';

export interface LoginProps {
    className?: string;
}

export const LoginFirstStep: React.FC<LoginProps> = (props: LoginProps) => {

  const [email, setEmail] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const error: string | null = useSelector((state:RootState) => state.auth.error);
  const loading: boolean = useSelector((state:RootState) => state.auth.loading);


  return (
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent:'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src='https://firebasestorage.googleapis.com/v0/b/syllotips-dev.appspot.com/o/utils%2Flogotype.png?alt=media&token=c2758ba0-8089-4c6c-8814-204574e834fe' />
          
          <Box component="form" noValidate sx={{ mt: 2 }} display='flex' flexDirection={'column'} alignItems={'center'} width={'100%'}>
            <Typography color={theme.palette.textColor?.main} variant='h5'>
            {t('authPage.title')}
            </Typography>
            <TextField onKeyDown={
              (event) => {
                
                if (event.key === 'Enter'){
                  event.preventDefault() 
                  const data:TenantRequestModel = {
                    email:email
                }
                dispatch(retrieveTenantConfiguration(data));
                }
              }
            }
              error={error !== null}
              helperText={error !== null? error:null}
              InputProps={{
                endAdornment: loading?< CircularProgress sx={{backgroundColor:'transparent'}}/>:null,
              }}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              sx={{
                fontFamily: 'Roboto',
                textColor: theme.palette.textColor!.main
              }}
            />
            
            <Button 
            onClick= {
                () =>{
                    const data:TenantRequestModel = {
                        email:email
                    }
                    dispatch(retrieveTenantConfiguration(data));
                }
            }
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('authPage.nextStepButton')}
            </Button>
          </Box>
        </Box>
      </Container>
  );
}