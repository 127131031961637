import { Box, CardMedia, Stack, Typography, useTheme } from '@mui/material';
import logo from '../../../assets/Logox3.png';
import { useTranslation } from 'react-i18next';

export const NoTicketSelected: React.FC = () => {

    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Box display='flex' height='100%' alignItems='center' justifyContent='center' flexDirection='column' >
            <Stack alignItems='center' justifyContent='center' spacing={2}>
                <Box width='20%'>
                    <CardMedia
                        component="img"
                        image={logo}
                        sx={{
                            objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
                        }}
                    />
                </Box>

                <Typography variant='h6' color={theme.palette.textColor!.main} sx={{
                    textAlign: 'center'
                }}>{t('customerServicePage.customerServiceHelperText')}</Typography>
            </Stack>
        </Box>
    );

}