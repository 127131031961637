export enum FileType{
    IMAGE=0,
    DOCUMENT = 1
}
export interface AttachmentModel {
    name: string;
    type: FileType;
    url: string;
    storageRef: string;
}

export interface Attachment {
    name: string;
    url: string;
    storageRef:string;
    type:FileType,
    createdAt: string;
    updatedAt: string;
  }
