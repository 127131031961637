import { Box, Chip, Stack, Typography, useTheme} from "@mui/material";
import { useEffect, useState } from "react";
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "@firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { ErrorComponent, ErrorType } from "../../../components/error-component/error_component";
import { useTranslation } from "react-i18next";
import { TicketModel, TicketType } from "../models/response/TicketModel";
import { fetchTickets, selectTicket } from "../api/customer_service_action";
import { UserModel } from "../../../models/response/user_model";
import { ChooseOrganizationsPopOver } from "./customer_service_organization_pop_over";
import { ChooseOrganization } from "./choose_organization";
import { TicketActionButton } from "./ticket_action_button";
import { TicketStatus } from "../api/customer_service_state";
import { OrganizationModel } from "../../../models/response/organization_model";


export const CustomerServiceSidebar = () => {

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [openedPopOver, setOpenedPopOver] = useState(false);
    
    const handleChannelSelectionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenedPopOver(true);
    };

    const handleCloseChannelSelectionClick = () => {
        setAnchorEl(null);
        setOpenedPopOver(false);
    };

  const theme = useTheme();
  
  const dispatch = useDispatch<AppDispatch>();
  const {t,i18n} = useTranslation();
  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const tickets: TicketModel[] = useSelector((state: RootState) => state.customerService.tickets);
  const currentTicket: TicketModel | undefined = useSelector((state: RootState) => state.customerService.currentTicket);
  const currentOrganization: OrganizationModel | undefined = useSelector((state: RootState) => state.customerService.currentOrganization);
  const ticketStatus: TicketStatus = useSelector((state: RootState) => state.customerService.ticketStatus);
  
  useEffect(() => {
    if (user && currentOrganization){
        
        const db = getFirestore();
        const q = query(collection(db, "Organizations",currentOrganization.id, "Tickets"),
        where('isSolved','==', ticketStatus === 'open'?false:true), orderBy('updatedAt','desc'));
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const tickets: TicketModel[] = querySnapshot.docs.map(doc => doc.data() as TicketModel);
            dispatch(fetchTickets(tickets))
        });

        return () => {
            unsubscribe(); 
          };
    }
    
  },[dispatch,user,currentOrganization,ticketStatus]);

  return (
    <>
      <Box
        flexGrow={1}
        sx={{
          position: "relative",
          width: '250px',
          maxWidth: '320px',
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box onClick={handleChannelSelectionClick}>
          <ChooseOrganization 
            onClick={() => {}} 
            title={currentOrganization === undefined? 'Scegli organizzazione': currentOrganization.name}/>
        </Box>
        
        <Stack p={1} spacing={2} sx={{ maxHeight: "90%" }}>
          <TicketActionButton/>
          <Stack sx={{ 
            flexGrow: 1, 
            height: "100%",
            overflowY:'scroll',
            '&::-webkit-scrollbar': {
                display: 'none', // Customize the width of the scrollbar
              } }}>
            <Box >
              <Stack spacing={1} >
                <Typography variant="subtitle2" sx={{ color: "#676667" }}>
                {t('customerServicePage.sidebarDescription')}
                </Typography>
                {tickets.map((ticket,index) => 
                  <Box 
                  onClick={() => dispatch(selectTicket(ticket))}
                  key={index} borderRadius={'10px'} p={1} 
                 
                  sx={{
                    backgroundColor:(currentTicket && ticket.id ===currentTicket.id)?'rgba(245, 245, 245, 1)':undefined,
                    '&:hover':{backgroundColor:'rgba(245, 245, 245, 1)', cursor: 'pointer'
                  }}}
                  >
                    <Stack direction='column'>
                        <Typography>{ticket.title}</Typography>
                        <Box>
                        <Chip variant="outlined" size={'small'} 
                          sx={{
                              color:'white', 
                              backgroundColor:ticket.type === TicketType.issue?'rgba(77, 160, 217, 1)':'rgba(140, 26, 106, 1)', 
                              '&:hover': { cursor: 'pointer' } }}
                          label={ticket.type  === TicketType.issue?'Issue':'Enhancement'} />
                        </Box>
                    </Stack>
                  </Box>)}                
                {
                  tickets.length === 0 && 
                  <Box paddingTop={'50%'} display='flex'  height='100%'  >
                    <ErrorComponent caption='' image={ErrorType.card} width={'100%'} height={'100%'}/>
                  </Box>
                }
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <ChooseOrganizationsPopOver open={openedPopOver} anchor={anchorEl} onClose={handleCloseChannelSelectionClick}/>
      </Box>
    </>
  );
};