import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { LoginModel, ResetPasswordLoginModel, TenantLoginModel, TenantRequestModel } from '../models/request/login_model';
import { doc, getDoc, getFirestore, updateDoc } from '@firebase/firestore';
import { UserModel } from '../../../models/response/user_model';
import { getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from '@firebase/auth';



export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (loginModel: LoginModel, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const auth = getAuth();
      auth.tenantId = loginModel.tenantId;
      const response = await signInWithEmailAndPassword(auth, loginModel.email, loginModel.password);
      const { uid } = response.user;
      
      const userDocumentReference = doc(db, 'Users', uid);
      const userDocument = await getDoc(userDocumentReference);
     
      return userDocument.data() as UserModel;
      
    } catch (error:any) {
      console.log(error.toString());
      return rejectWithValue(error.message);
    }   
  }
);

export const alreadyLoggedUser = createAsyncThunk(
  'auth/alreadyLoggedUser',
  async (data, { rejectWithValue }) => {
    
    try{
    
      const db = getFirestore();
      const auth = getAuth();
      var uid = '';
      const user: UserModel | undefined = await new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) =>  {
          if (user) {
            
            const userDocumentReference = doc(db, 'Users', user.uid);
            const userDocument = await getDoc(userDocumentReference);
            
            await updateDoc(userDocumentReference,{
              'lastLogin': new Date().toISOString()
            })
            resolve(userDocument.data() as UserModel);
            
            
          } else {
            resolve(undefined);
          }
        });

      });
      return user;

    }  catch (error:any) {
      return rejectWithValue(error.message);
    } 
  });

export const retrieveTenantConfiguration = createAsyncThunk(
  'auth/retrieveTenantConfiguration',
  async (data:TenantRequestModel,{rejectWithValue}) => {
    try{

      var apiUrl = '';
      var headers = {};


      if (process.env.REACT_APP_ENV === "development"){
        apiUrl = 'https://tenant.manager.dev.syllotips.app/tenant/get-tenant-for-email';
      }else{
        apiUrl = 'https://tenant.manager.prod.syllotips.app/tenant/get-tenant-for-email';
      }

      headers = {
        ...(process.env.REACT_APP_ENV === "development") ? {'x-api-key': process.env.REACT_APP_TENANT_API_KEY_DEV} : {'x-api-key': process.env.REACT_APP_TENANT_API_KEY_PROD},
        'Content-Type': 'application/json; charset=UTF-8',
      };
      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers:headers,
        mode:"cors",
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const responseData = await response.json() as TenantLoginModel;
      
      if(responseData.message){
        throw new Error('user not found');
      }

      return {
        email: data.email,
        tenantId: responseData.tenantId,
        loginWithMicrosoft: responseData.hasMicrosoft,
        loginWithPassword: responseData.hasPassword,
      }
    
    
    }catch(error:any){
      return rejectWithValue(error.toString());
    }
  }
);

export const resetYourPassword = createAsyncThunk(
  'auth/resetYourPassword',
  async (data:ResetPasswordLoginModel, {rejectWithValue}) => {
    try{
      const auth = getAuth();
      auth.tenantId = data.tenantId;
      await sendPasswordResetEmail(auth, data.email);
    }catch(error:any){
      return rejectWithValue(error.toString());
    }
  }
);


export const logout = createAsyncThunk(
  'auth/logout',
  async (data, { dispatch, rejectWithValue }) => {
    try{

      const db = getFirestore();
      const auth = getAuth();

      await signOut(auth);
    
    }catch(error:any){
      return rejectWithValue(error.toString());
    }
  });


