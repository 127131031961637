import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    "announcementPopover": {
                        "title": "Announcements",
                        "actionButton": "Post",
                        "emptyPageTitle": "Nothing to see here!",
                        "emptyPageDescription": "Announcements will be visible in this dialog.",
                        "viwedDescription": "viewed",
                        "gotItDescription": "got it!",
                        "contactActionButton": "Contact {{value}}"
                    },
                    "authPage": {
                        "title": "Access to SylloTips",
                        "nextStepButton": "Continue",
                        "forgotYourPasswordCaption": "Forgot yout password? Click here to create a new one",
                        "resetPasswordCaption": "We sent an email to {{email}}. Follow the instructions to reset your password!",
                        "signInButton": "Sign In",
                        "loginWithProvider": "or login with",
                        "microsoftProvider": "Continue with Microsoft"
                    },
                    "cardCreationPage": {
                        "discussionTitle": "Create discussion",
                        "announcementTitle": "Create announcement",
                        "actionButton": "Post",
                        "selectChannelButton": "Select a channel",
                        "cardTitlePlaceHolder": "Title",
                        "submitAnywayActionButton": "Submit anyway"
                    },
                    "channelSideBar": {
                        "channelCaption": "Your channels",
                        "mutedChannelCaption": "Muted channels",
                        "muteChannelAction":"Mute channel",
                        "unmuteChannelAction": "Unmute channel",
                        "leaveChannelAction": "Leave channel",
                        "title": "Channels",
                        "chatTitle": "Chat",
                        "announcementTitle": "Announcements",
                        "discussionTitle": "Discussions",
                        "documentTitle": "Documents",
                        "memberTitle": "Members"
                    },
                    "channelAnnouncementPage": {
                        "title": "{{channelName}}'s announcements",
                        "actionButton": "Create announcement",
                        "emptyCaption": "No announcements yet",
                        "emptyDescription": "Announcement from this channel will be visible here."
                    },
                    "channelChatPage": {
                        "title": "{{channelName}}'s chat",
                        "inputBasePlaceHolder": "Write a message"
                    },
                    "channelDiscussionPage": {
                        "title": "{{channelName}}'s discussions",
                        "actionButton": "Create discussion",
                        "discussionForYouTab": "For you",
                        "allDiscussionTab": "All",
                        "emptyCaption": "No discussions for you!",
                        "emptyDescriptionActiveDiscussion": "As soon as a discussion will be open you will find it here.",
                        "emptyDescriptionCloseDiscussion": "When a discussion will be closed, you wil find it here.",
                        "emptyCaptionAllChannel": "No discussions in this channel",
                        "emptyDescriptionActiveDiscussionAllChannel": "Open discussion meant for you will appear here.",
                        "emptyDescriptionCloseDiscussionAllChannel": "As soon as a discussion will be open you will find it here.",
                        "activeDiscussionButton": "Active discussions",
                        "closeDiscussionButton": "Closed discussions"
                    },
                    "channelDocumentPage": {
                        "title": "{{channelName}}'s documents",
                        "fileNameTableLabel": "File name",
                        "uploadedByTableLabel": "Uploaded by",
                        "createAdtTableLabel": "Added on",
                        "sizeTableLabel": "Size",
                        "statusTableLabel": "Status",
                        "actionTableLabel": "Action",
                        "searchHelperText": "Search documents...",
                        "actionButton": "Upload documents",
                        "uploadErrorCode": "ERROR",
                        "uploadSuccessCode": "UPLOADED",
                        "uploadPendingCode": "PENDING",
                        "noRowCaption": "No document found, change filter or upload new documents"
                    },
                    "channelMemberPage": {
                        "title": "{{channelName}}'s members",
                        "actionButton": "Add members",
                        "titleAddMember": "Add members to {{channelName}}",
                        "addMembersActionButton": "Done",
                        "removeMemberActionButton": "Remove",
                        "addMemberActionButton": "Add",
                        "searchMemberHelperText": "Search member..."
                    },
                    
                    "chatPage": {
                        "sideBarChatTitle": "Chats",
                        "sideBarSearchHelperText": "Search chats...",
                        "sideBarChatDescription": "All Chats",
                        "dialogTitle": "New message",
                        "dialogSearchHelperText": "Search users...",
                        "createConversationButton": "Start a conversation",
                        "chatFooterHelperText": "Write a message..."
                    },
                    "createChannelPage": {
                        "title": "Create new channel",
                        "selectMembersActionButton": "Select members",
                        "titleFieldHelperText": "Title",
                        "descriptionFieldHelperText": "Description",
                        "publicChannelGroupButton": "Public channel",
                        "publicChannelFirstDescription": "Public channels are intended for larger groups; discussions, announcements and documents from a public channel appear in the search results of all users.",
                        "publicChannelSecondDescription": "In addition, new discussions are automatically forwarded only to the most relevant channel members.",
                        "privateChannelGroupButton": "Private channel",
                        "privateChannelFirstDescription": "Private channels are recommended for smaller teams; discussions, announcements, and documents in a private channel are visible only to members.",
                        "privateChannelSecondDescription": "In addition, new discussions are automatically notified to all members.",
                        "selectMemberTitle": "Select member",
                        "selectMemberFieldHelperText": "Search member..."
                    },
                    "customerServicePage":{
                        "title": "How can we help you?",
                        "titleFieldHelperText": "Title",
                        "descriptionFieldHelperText": "Tell us what you think...",
                        "actionButton": "Send",
                        "sidebarTitle": "Ticket",
                        "sidebarDescription": "All tickets",
                        "ticketTypeSelectionButton": "Select ticket type",
                        "ticketTypeOne": "Enhancement",
                        "ticketTypeTwo": "Issue",
                        "ticketSentDescription": "Thank you for your time! \n We always value your useful feedback!",
                        "ticketSentActionButton": "Go to your tickets",
                        "markAsSolvedAction": "Mark as solved",
                        "deleteAction": "Delete ticket",
                        "customerServiceHelperText":"Here you can let us know anything related to bugs or features you’d like to see inside SylloTips",
                        "openTicketActionButton": "open",
                        "closedTicketActionButton": "closed"
                    },
                    "expertPage": {
                        "goBackButton": "Go Back",
                        "sendRewardButton": "Send reward",
                        "firstTitle": "Let",
                        "secondTitle": "know you appreciate the help",
                        "firstDescription": "You have",
                        "secondDescription": "points left",
                        "helperText": "Please choose at most 3 expertise",
                        "sendGiftButton": "Send gift to {{displayName}}",
                        "contactButton": "Contact",
                        "dashboardTitle": "Dashboard",
                        "dashboardPointDescription": "Total points",
                        "dashboardPositionDescription": "Latest placing",
                        "dashboardTrophyDescription": "Times in top 3",
                        "barPlotTitle": "Activity",
                        "weeklyDate": "week",
                        "monthlyDate": "month",
                        "yearlyDate": "year",
                        "medalTitle": "Medals",
                        "achievementTitle": "Achievements",
                        "levelDescription": "Level {{level}}",
                        "bestExpertiseTitle": "Best expertise",
                        "expertiseTitle": "Expertise"
                    },
                    "expertisePopOver": {
                        "title": "Expertise",
                        "expertiseNameTableLabel": "Expertise",
                        "showAll": "show all",
                        "expertiseScoreTableLabel": "Score",
                        "searchHelperText": "Search expertises...",
                        "multipleSelection": "selected"
                    },
                    "feedPage": {
                        "actionButton": "Create a post",
                        "filterButton": "Filter by",
                        "filterButtonCaption": "Select channel",
                        "sortButton": "Sort by",
                        "sortFirstOption": "Most recent update",
                        "sortSecondOption": "Most discussed",
                        "emptyTitle": "Nothing to see here!",
                        "emptyDescription": "New and updated discussions will appear here.",
                        "leaveDiscussionAction": "leave discussion",
                        "deleteDiscussionAction": "close discussion",
                        "newUpdateCaption": "{{value}} new updates",
                        "newDiscussionCaption": "new discussion",
                        "closedDiscussionCaption": "closed discussion"
                    },
                    "gptPage": {
                        "allChatTitle": "All Chats",
                        "gptHelperText": "Hi! How can I assist you today?",
                        "footerHelperText": "Type your message...",
                        "gptMessageReferences": "References",
                        "gptWaitingMessage": "Please hold on for a moment while I gather and analyse the necessary documents to address your questions",
                        "gptCreateAQuestionDescription": "Not satisfied of your answer?",
                        "gptCreateAQuestionButton": "Create a discussion"
                    },
                    "introductionPage": {
                        "titleFirstStep": "About you",
                        "addProfilePicDescription": "Add a profile picture",
                        "tellAboutYouDescription": "Tell us something about you",
                        "tellAboutYouHelperText": "Sum up your role in your company",
                        "continueButton": "continue",
                        "titleSecondStep": "Tell us what you are expert on",
                        "searchHelperText": "Search expertise in your organization",
                        "doneButton": "Update your profile"
                    },
                    "searchPage": {
                        "filterButton": "Filter by",
                        "filterButtonCaption": "Select channels",
                        "helperText": "Search inside SylloTips",
                        "discussionTab": "Discussions ({{value}})",
                        "announcementTab": "Announcements ({{value}})",
                        "expertTab": "Experts ({{value}})",
                        "colleaguesTab": "Colleagues ({{value}})",
                        "documentTab": "Documents ({{value}})",
                        "documentDescription": "Uploaded by {{value}}"
                    },
                    "sideBar":{
                        "homeCaption": "Home",
                        "yourThreadCaption": "Discussions",
                        "followedThreadCaption": "Followed",
                        "chatCaption":"Messages",
                        "gptCaption": "Sybil",
                        "customerServiceCaption": "Feedback",
                        "channelCaptions": "Channels"

                    },
                    "threadPage": {
                        "contributorsIconDescription": "contributors",
                        "answersIconDescription": "answers",
                        "deleteThread": "Delete discussion",
                        "editThread": "Edit discussion",
                        "followThread": "Follow discussion",
                        "unfollowThread": "Unfollow discussion",
                        "saveButton": "save",
                        "sendButton": "Send answer"
                    },
                    "userThreadPage":{
                        "emptyTitle": "Nothing to see here!",
                        "emptyDescription":"Your discussions will appear here."
                    },
                    "userFollowedPage":{
                        "emptyTitle": "Nothing to see here!",
                        "emptyDescription":"Followed discussions will appear here."
                    },
                    "notFoundPage": {
                        "title": "404 - Not Found",
                        "description": "Sorry, the thread you are looking for does not exist."
                    },
                    "notificationPopOver": {
                        "title": "Notification"
                    },
                    "leaderboardPopOver": {
                        "title": "Leaderboard"
                    },
                    "profilePopOver": {
                        "profileTitle": "Profile",
                        "dashboard": "Dashboard"
                    },
                    "alertDialog": {
                        "alertTitle": "Oops, it appears there was an issue!",
                        "sizeMessage": "the size of the question body is larger then 1 Mb",
                        "documentUploadLimit": "You have selected too many documents. Please limit your selection to a maximum of 10 documents at a time.",
                        "documentSizeLimit": "The total size of documents exeeds the maximum size allowed (50Mb)"
                    },
                    "toolTips": {
                        "skipButton": "Skip",
                        "backButton": "Back",
                        "forwardButton": "Next",
                        "first": "Welcome to SylloTips! Let’s take a quick tour of the app's key features and sections.",
                        "second": "Start from here to create a new discussion for your colleagues. Discussions are the main tool to find answers to your doubts.",
                        "third": "Here you will find announcements for you! check them out to discover any news!",
                        "forth": "Here you will find your expertise! gain them interacting with your colleagues"
                    }
                }
            },
            it: {
                translation: {
                    "announcementPopover": {
                        "title": "Annunci",
                        "actionButton": "Pubblica",
                        "emptyPageTitle": "Ancora nulla da vedere qui!",
                        "viwedDescription": "visualizzato",
                        "gotItDescription": "got it!",
                        "emptyPageDescription": "Gli annunci saranno visibili in questa sezione",
                        "contactActionButton": "Contatta {{value}}"
                    },
                    "authPage": {
                        "title": "Accedi a SylloTips",
                        "nextStepButton": "Continua",
                        "forgotYourPasswordCaption": "Hai dimenticato la tua password? Clicca qui per impostarne una nuova",
                        "resetPasswordCaption": "Abbiamo inviato un'email a {{email}}. Segui le istruzioni per reimpostare la tua password!",
                        "signInButton": "Accedi",
                        "loginWithProvider": "o accedi con",
                        "microsoftProvider": "Continua con Microsoft"
                    },
                    "cardCreationPage": {
                        "discussionTitle": "Crea una nuova discussione",
                        "announcementTitle": "Pubblica un annuncio",
                        "actionButton": "Pubblica",
                        "selectChannelButton": "Seleziona un canale",
                        "cardTitlePlaceHolder": "Titolo",
                        "submitAnywayActionButton": "Invia comunque"
                    },
                    "channelSideBar": {
                        "chatTitle": "Chat",
                        "announcementTitle": "Annunci",
                        "discussionTitle": "Discussioni",
                        "documentTitle": "Documenti",
                        "memberTitle": "Membri",
                        "title": "Canali",
                        "leaveChannelAction": "Lascia il canale",
                        "unmuteChannelAction": "Attiva notifiche",
                        "muteChannelAction": "Disattiva notifiche",
                        "mutedChannelCaption": "Canali mutati",
                        "channelCaption": "I tuoi canali"
                    },
                    "channelAnnouncementPage": {
                        "title": "Annunci di {{channelName}}",
                        "actionButton": "Pubblica un annuncio",
                        "emptyCaption": "Non ci sono ancora annunci in questo canale",
                        "emptyDescription": "Gli annunci di questo canale saranno visibili qui"
                    },
                    "channelChatPage": {
                        "title": "Chat di {{channelName}}",
                        "inputBasePlaceHolder": "Scrivi un messaggio"
                    },
                    "channelDiscussionPage": {
                        "title": "Discussioni di {{channelName}}",
                        "actionButton": "Crea una nuova discussione",
                        "discussionForYouTab": "Per te",
                        "allDiscussionTab": "Tutte",
                        "emptyCaption": "Nessuna discussione per te!",
                        "emptyDescriptionActiveDiscussion": "Appena una discussione sarà aperta la troverai qui.",
                        "emptyDescriptionCloseDiscussion": "Quando una discussione sarà chiusa, la troverai qui.",
                        "emptyCaptionAllChannel": "Nessuna discussione in questo canale",
                        "emptyDescriptionActiveDiscussionAllChannel": "Le discussioni aperte per te appariranno qui.",
                        "emptyDescriptionCloseDiscussionAllChannel": "Appena una discussione sarà aperta la troverai qui.",
                        "activeDiscussionButton": "Discussioni attive",
                        "closeDiscussionButton": "Discussioni chiuse"
                    },
                    "channelDocumentPage": {
                        "title": "Documenti di {{channelName}}",
                        "fileNameTableLabel": "Nome del file",
                        "uploadedByTableLabel": "Caricato da",
                        "createAdtTableLabel": "Aggiunto il",
                        "sizeTableLabel": "Dimensioni",
                        "statusTableLabel": "Stato",
                        "actionTableLabel": "Azione",
                        "searchHelperText": "Cerca documenti...",
                        "actionButton": "Carica documenti",
                        "uploadErrorCode": "ERRORE",
                        "uploadSuccessCode": "CARICATO",
                        "uploadPendingCode": "IN SOSPESO",
                        "noRowCaption": "Nessun documento trovato, cambia filtro o carica nuovi documenti"
                    },
                    "channelMemberPage": {
                        "title": "Membri del canale {{channelName}}",
                        "actionButton": "Aggiungi nuovi membri",
                        "titleAddMember": "Aggiungi membri a {{channelName}}",
                        "addMembersActionButton": "Fatto",
                        "removeMemberActionButton": "Rimuovi",
                        "addMemberActionButton": "Aggiungi",
                        "searchMemberHelperText": "Cerca membro..."
                    },
                    "chatPage": {
                        "sideBarChatTitle": "Chat",
                        "sideBarSearchHelperText": "Cerca nelle chat...",
                        "sideBarChatDescription": "Tutte le chat",
                        "dialogTitle": "Nuovo messaggio",
                        "dialogSearchHelperText": "Cerca tra gli utenti...",
                        "createConversationButton": "Inizia una conversazione",
                        "chatFooterHelperText": "Scrivi un messaggio..."
                    },
                    "createChannelPage": {
                        "title": "Crea un nuovo canale",
                        "selectMembersActionButton": "Seleziona membri",
                        "titleFieldHelperText": "Titolo",
                        "descriptionFieldHelperText": "Descrizione",
                        "publicChannelGroupButton": "Canale pubblico",
                        "publicChannelFirstDescription": "I canali pubblici sono destinati a gruppi più numerosi; discussioni, annunci e documenti di un canale pubblico appaiono nei risultati di ricerca di tutti gli utenti. ",
                        "publicChannelSecondDescription": "Inoltre, le nuove discussioni vengono inoltrate in modo automatico solo ai membri più rilevanti del canale.",
                        "privateChannelGroupButton": "Canale privato",
                        "privateChannelFirstDescription": "I canali privati sono consigliati per team più piccoli; discussioni, annunci e documenti di un canale privato sono visibili ai soli membri.",
                        "privateChannelSecondDescription": "Inoltre, le nuove discussioni vengono notificate automaticamente a tutti i membri.Seleziona membro",
                        "selectMemberTitle": "Seleziona membro",
                        "selectMemberFieldHelperText": "Cerca membro..."
                    },
                    "customerServicePage": {
                        "title": "Come posso aiutarti?",
                        "titleFieldHelperText": "Titolo",
                        "descriptionFieldHelperText": "Facci sapere cosa pensi...",
                        "actionButton": "Invia",
                        "sidebarTitle": "Ticket",
                        "sidebarDescription": "Tutti i ticket",
                        "ticketTypeOne": "Richiesta di funzionalità",
                        "ticketTypeTwo": "Problema",
                        "ticketTypeSelectionButton": "Seleziona la tipologia di ticket",
                        "ticketSentDescription": "Grazie per il tuo tempo! \\n Apprezziamo sempre il feedback dei nostri utenti!",
                        "ticketSentActionButton": "Vai ai tuoi ticket",
                        "markAsSolvedAction": "Segna come risolto",
                        "deleteAction": "Elimina",
                        "customerServiceHelperText": "Qui puoi farci sapere tutto ciò che riguarda bug o funzionalità che vorresti vedere all'interno di SylloTips.",
                        "openTicketActionButton": "aperto",
                        "closedTicketActionButton": "chiuso"
                    },
                    "expertPage": {
                        "goBackButton": "Torna indietro",
                        "sendRewardButton": "Invia ricompensa",
                        "firstTitle": "Fai sapere ",
                        "secondTitle": "che apprezzi il suo aiuto",
                        "firstDescription": "Ti rimangono",
                        "secondDescription": "punti",
                        "helperText": "Scegli al massimo 3 competenze",
                        "sendGiftButton": "Invia regalo a {{displayName}}",
                        "contactButton": "Contatta",
                        "dashboardTitle": "Dashboard",
                        "dashboardPointDescription": "Punti totali",
                        "dashboardPositionDescription": "Ultimo posizionamento",
                        "dashboardTrophyDescription": "Numero di volte nei primi 3",
                        "barPlotTitle": "Attività",
                        "weeklyDate": "settimana",
                        "monthlyDate": "mese",
                        "yearlyDate": "anno",
                        "medalTitle": "Medaglie",
                        "achievementTitle": "Traguardi",
                        "levelDescription": "Livello {{level}}",
                        "bestExpertiseTitle": "Migliori competenze",
                        "expertiseTitle": "Competenza"
                    },
                    "expertisePopOver": {
                        "title": "Competenza",
                        "expertiseNameTableLabel": "Competenza",
                        "showAll": "mostra tutto",
                        "expertiseScoreTableLabel": "Punteggio",
                        "searchHelperText": "Cerca competenze...",
                        "multipleSelection": "selezionate"
                    },
                    "feedPage": {
                        "actionButton": "Crea un post",
                        "filterButton": "Filtra per",
                        "filterButtonCaption": "Seleziona un canale",
                        "sortButton": "Ordina per",
                        "sortFirstOption": "Aggiornamenti più recenti",
                        "sortSecondOption": "Più discusse",
                        "emptyTitle": "Niente da vedere qui!",
                        "emptyDescription": "Le discussioni nuove e aggiornate appariranno qui.",
                        "leaveDiscussionAction": "Lascia la discussione",
                        "deleteDiscussionAction": "Chiudi la discussione",
                        "newUpdateCaption": "{{value}} nuovi aggiornamenti",
                        "newDiscussionCaption": "nuova discussione",
                        "closedDiscussionCaption": "La discussione è chiusa"
                    },
                    "gptPage": {
                        "allChatTitle": "Tutte le chat",
                        "gptHelperText": "Ciao! Come posso aiutarti oggi?",
                        "footerHelperText": "Digita il tuo messaggio...",
                        "gptMessageReferences": "Riferimenti",
                        "gptWaitingMessage": "Per favore attendi un momento mentre raccolgo e analizzo i documenti necessari per rispondere alle tue domande.",
                        "gptCreateAQuestionDescription": "Non sei soddisfatto della risposta?",
                        "gptCreateAQuestionButton": "Apri una nuova discussione"
                    },
                    "introductionPage": {
                        "titleFirstStep": "Su di te",
                        "addProfilePicDescription": "Aggiungi una foto del profilo",
                        "tellAboutYouDescription": "Dicci qualcosa su di te",
                        "tellAboutYouHelperText": "Sintetizza il tuo ruolo nell'organizzazione",
                        "continueButton": "continua",
                        "titleSecondStep": "Dicci in cosa sei esperto",
                        "searchHelperText": "Cerca competenze nella tua organizzazione",
                        "doneButton": "Aggiorna il tuo profilo"
                    },
                    "searchPage": {
                        "filterButton": "Filtra per",
                        "filterButtonCaption": "Seleziona canali",
                        "helperText": "Cerca all'interno di SylloTips",
                        "discussionTab": "Discussioni ({{value}})",
                        "announcementTab": "Annunci ({{value}})",
                        "expertTab": "Esperti ({{value}})",
                        "colleaguesTab": "Colleghi ({{value}})",
                        "documentTab": "Documenti ({{value}})",
                        "documentDescription": "Caricato da {{value}}"
                    },
                    "sideBar": {
                        "homeCaption": "Home",
                        "yourThreadCaption": "Discussioni",
                        "followedThreadCaption": "Seguiti",
                        "chatCaption": "Messaggi",
                        "gptCaption": "Sybil",
                        "customerServiceCaption": "Feedback",
                        "channelCaptions": "Canali"
                    },
                    "threadPage": {
                        "contributorsIconDescription": "commenti",
                        "answersIconDescription": "risposte",
                        "deleteThread": "Elimina la discussione",
                        "editThread": "Modifica la discussione",
                        "followThread": "Segui la discussione",
                        "unfollowThread": "Smetti di seguire la discussione",
                        "saveButton": "salva",
                        "sendButton": "Invia la risposta"
                    },
                    "userThreadPage": {
                        "emptyTitle": "Niente da vedere qui!",
                        "emptyDescription": "Le tue discussioni  appariranno qui."
                    },
                    "userFollowedPage": {
                        "emptyTitle": "Niente da vedere qui!",
                        "emptyDescription": "Le discussioni seguite appariranno qui."
                    },
                    "notFoundPage": {
                        "title": "404 - Non trovato",
                        "description": "Spiacenti, la discussione che stai cercando non esiste."
                    },
                    "notificationPopOver": {
                        "title": "Notifica"
                    },
                    "leaderboardPopOver": {
                        "title": "Classifica"
                    },
                    "profilePopOver": {
                        "profileTitle": "Profilo",
                        "dashboard": "Dashboard"
                    },
                    "alertDialog": {
                        "alertTitle": "Ops, sembra ci sia stato un problema!",
                        "sizeMessage": "la dimensione del corpo della domanda è maggiore di 1 Mb",
                        "documentUploadLimit": "Hai selezionato troppi documenti. Limita la selezione a un massimo di 10 documenti alla volta.",
                        "documentSizeLimit": "La dimensione totale dei documenti supera la dimensione massima consentita (50Mb)"
                    },
                    "toolTips": {
                        "skipButton": "Salta",
                        "backButton": "Indietro",
                        "forwardButton": "Avanti",
                        "first": "Benvenuto in SylloTips! Facciamo un breve tour delle funzionalità principali dell'app e delle sezioni.",
                        "second": "Inizia da qui per creare una nuova discussione per i tuoi colleghi. Le discussioni sono il principale strumento per trovare risposte ai tuoi dubbi.",
                        "third": "Qui troverai gli annunci per te! Controllali per scoprire eventuali novità!",
                        "forth": "Qui troverai le tue competenze! Acquisiscile interagendo con i tuoi colleghi."
                    }
                }
            }
        }
    });

export default i18n;