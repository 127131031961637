import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { TicketModel } from "../models/response/TicketModel";
import { MessageModel } from "../models/response/TicketMessageModel";
import { SendMessageModel } from "../models/request/send_message_model";
import { TicketStatus } from "./customer_service_state";
import { Attachment, AttachmentModel } from "../models/response/attachment_model";
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc } from "@firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadString } from "@firebase/storage";
import { OrganizationModel } from "../../../models/response/organization_model";
import { MarkAsSolvedTicketModel } from "../models/request/mark_as_solved_ticket_model";

export const fetchTickets = createAction(
    'customerService/fetchTickets',
    function prepare(tickets: TicketModel[]) {
        return {
            payload: {
                tickets: tickets
            },
        }
    });


export const clearCache = createAction(
    'customerService/clearCache',
    function prepare() {
        return {
            payload: {

            },
        }
    });

export const changeTicketStatus = createAction(
    'customerService/changeTicketStatus',
    function prepare(status: TicketStatus) {
        return {
            payload: {
                status: status
            },
        }
    });

export const fetchMessages = createAction(
    'customerService/fetchMessages',
    function prepare(messages: MessageModel[]) {
        return {
            payload: {
                messages: messages
            },
        }
    });

export const markTicketAsSolved = createAsyncThunk(
        'customerService/markTicketAsSolved',
        async (data: MarkAsSolvedTicketModel, { rejectWithValue, }) => {
            try {
                
                const db = getFirestore();
                const ticketQuery = doc(db, 'Organizations', data.organizationId, 'Tickets', data.ticketId);
                await updateDoc(ticketQuery, {isSolved: true});
            } catch (error: any) {
                console.log(error.toString())
                return rejectWithValue(error.toString());
            }
        });


export const selectTicket = createAction(
    'customerService/selectTicket',
    function prepare(ticket: TicketModel) {
        return {
            payload: {
                ticket: ticket
            },
        }
    });



export const addAttachments = createAction(
    'customerService/addAttachments',
    function prepare(attachments: AttachmentModel[]) {
        return {
            payload: {
                attachments: attachments
            },
        }
    });

export const updateText = createAction(
    'customerService/updateText',
    function prepare(text: string) {
        return {
            payload: {
                text: text
            },
        }
    });


export const sendTicketMessage = createAsyncThunk(
    'customerService/sendTicketMessage',
    async (data: SendMessageModel, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const storage = getStorage();
            const messageQuery = doc(collection(db, 'Organizations', data.organizationId, 'Tickets', data.ticketId, 'Messages'));

            const date = new Date().toISOString();

            const attachments: Attachment[] = await Promise.all(
                data.message.attachments.map(async (attachment) => {
                    const storageRefString = 'organizations/' + data.organizationName + '/tickets/' + data.ticketId + '/messages/' + messageQuery.id + '/' + attachment.name;
                    const storageRef = ref(storage, storageRefString);
                    await uploadString(storageRef, attachment.url, 'data_url');
                    const filePath = await getDownloadURL(storageRef);

                    const currentAttachment: Attachment = {
                        name: attachment.name,
                        url: filePath,
                        storageRef: storageRefString,
                        type: attachment.type,
                        createdAt: date,
                        updatedAt: date
                    }
                    return currentAttachment;
                })
            );

            const messageToSend: MessageModel = {
                ...data.message,
                createdAt: date,
                updatedAt: date,
                isFromSylloTips: false,
                attachments: attachments,
            }

            await setDoc(messageQuery, messageToSend)

        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    })

    export const selectCurrentOrganization = createAction(
        'organization/selectCurrentOrganization',
        function prepare(organization: OrganizationModel) {
            return {
                payload: {
                    organization: organization
                },
            } 
        });
    
    
    export const loadOrganizations = createAsyncThunk(
        'organization/loadOrganizations',
        async (data:{}, {rejectWithValue}) => {
            try{
    
                const db = getFirestore();
                const organizationQuery = query(collection(db, "Organizations",));
    
                const organizationDoc = await getDocs(organizationQuery);
    
                const organizations = organizationDoc.docs.map((doc) => doc.data() as OrganizationModel)
    
                return {
                    organizations: organizations
                }
    
    
            }catch(error:any){
                return rejectWithValue(error.toString());
            }
    
        }
    );