import { createTheme, responsiveFontSizes } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        iconColor?: Palette['primary'];
        textColor?: Palette['primary'];
        sliderBarColor?: Palette['primary'];
        chatIncomingBubbleColor?: Palette['primary'];
        chatOngoingBubbleColor?: Palette['primary'];
        iCanHelpColor?: Palette['primary'];
        myAnswerColor?: Palette['primary'];
        followColor?: Palette['primary'];
        skipColor?: Palette['primary'];

      }
      interface PaletteOptions {
        iconColor?: PaletteOptions['primary'];
        textColor?: PaletteOptions['primary'];
        sliderBarColor?: PaletteOptions['primary'];
        chatIncomingBubbleColor?: PaletteOptions['primary'];
        chatOngoingBubbleColor?: PaletteOptions['primary'];
        iCanHelpColor?: PaletteOptions['primary'];
        myAnswerColor?: PaletteOptions['primary'];
        followColor?: PaletteOptions['primary'];
        skipColor?: PaletteOptions['primary'];
      }
  }
  
let appTheme = createTheme({
    palette: {
        iconColor:{
          main: '#4a4a4a',
        
        },
        textColor: {
          main: '#4a4a4a', 
        },
        chatIncomingBubbleColor: {
          main: '#efefef',
        },
        chatOngoingBubbleColor:{
          main: '#5876ff',
        },
        sliderBarColor:{
          main: '#5876ff'
        },
        iCanHelpColor:{
          main: '#00be00',
        },
        myAnswerColor:{
          main: '#F29E4C',
        },
        followColor:{
          main: '#5876ff',
        },
        skipColor:{
          main: '#FF0000',
        }
    },
    typography: {
        fontFamily: [
          'Roboto',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Poppins',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
      
  });

  appTheme = responsiveFontSizes(appTheme)

  export default appTheme;