import { Chip, Stack } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/store"
import { TicketStatus } from "../api/customer_service_state";
import { useTranslation } from "react-i18next";
import { changeTicketStatus } from "../api/customer_service_action";

export const TicketActionButton: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();
    const ticketStatus = useSelector((state: RootState) => state.customerService.ticketStatus);
    
    
    return (
        <Stack direction={'row'} spacing={1} p={1} justifyContent={'space-around'}>
                    <Chip
                        sx={{
                            padding: '0px 8px',
                            margin: '1px',
                            color: ticketStatus === TicketStatus.open ? 'white' : null,
                            backgroundColor:  ticketStatus === TicketStatus.open ? 'black' : null
                        }}
                        label={t('customerServicePage.openTicketActionButton')}
                        size='small'
                        variant={ticketStatus === TicketStatus.open ? 'filled' : 'outlined'}
                        onClick={() => {dispatch(changeTicketStatus(TicketStatus.open ))}}
                    />
                    <Chip
                        sx={{
                            padding: '0px 8px',
                            margin: '1px',
                            color: ticketStatus === TicketStatus.closed ? 'white' : null,
                            backgroundColor: ticketStatus === TicketStatus.closed ? 'black' : null
                        }}
                        label={t('customerServicePage.closedTicketActionButton')}
                        size='small'
                        variant={ticketStatus === TicketStatus.closed ? 'filled' : 'outlined'}
                        onClick={() => { dispatch(changeTicketStatus(TicketStatus.closed )) }}
                    />
                </Stack>
    )
}