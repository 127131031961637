import './login.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../app/store';
import { loginUser, resetYourPassword } from '../../api/authAction';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, CssBaseline, Grid, Link, TextField, ThemeProvider, Typography, createTheme, useTheme } from '@mui/material';
import { AuthType } from '../../api/authentication_state';
import { useTranslation } from 'react-i18next';
import { LoginModel, ResetPasswordLoginModel } from '../../models/request/login_model';

export interface LoginProps {
  className?: string;
}

export const Login: React.FC<LoginProps> = (props: LoginProps) => {

  const email = useSelector((state: RootState) => state.auth.email);
  const [password, setPassword] = useState('');

  const currentTenant: string = useSelector((state: RootState) => state.auth.currentTenant);
  const loginWithPassword: boolean | undefined = useSelector((state: RootState) => state.auth.loginWithPassword);
  const forgotYourPassword: boolean  = useSelector((state: RootState) => state.auth.forgotYourPassword);

  const success: AuthType = useSelector((state: RootState) => state.auth.success);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const defaultTheme = createTheme();
  const theme = useTheme();
  const {t} = useTranslation();
  const error: string | null = useSelector((state:RootState) => state.auth.error);


  const handleLogin = async () => {

    const data: LoginModel = {
      email: email,
      password: password,
      tenantId: currentTenant,

    }
    await dispatch(loginUser(data));
  };

  useEffect(
    () => {
      if (success === AuthType.Success) {
        navigate('/home');
      }
    }, [success]
  )


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src='https://firebasestorage.googleapis.com/v0/b/syllotips-dev.appspot.com/o/utils%2Flogotype.png?alt=media&token=c2758ba0-8089-4c6c-8814-204574e834fe' />



          <Box component="form" noValidate sx={{ mt: 2 }} display='flex' flexDirection={'column'} alignItems={'center'} width={'100%'}>
            

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              disabled
              sx={{
                fontFamily: 'Roboto',
                textColor: theme.palette.textColor!.main
              }}
              value={email}
            />

            {
              loginWithPassword && <TextField
                error={error !== null}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                helperText={error !== null?error:null}
                sx={{
                  fontFamily: 'Roboto',
                  textColor: theme.palette.textColor!.main
                }}
              />
            }
            {
              loginWithPassword &&
              <Link
              sx={{
                '&:hover':{
                  cursor:'pointer'
                }
              }}
              onClick={
                () => {
                  const data:ResetPasswordLoginModel = {
                    email: email,
                    tenantId: currentTenant
                  }
                  dispatch(resetYourPassword(data))
                }
              }
              >{t('authPage.forgotYourPasswordCaption')}</Link>
            }
            {
              forgotYourPassword &&
              <Typography>
                {t('authPage.resetPasswordCaption', { email: email })}
              </Typography>
            }
            {
              loginWithPassword && <Button
                onClick={handleLogin}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontFamily: 'Roboto' }}
              >
                {t('authPage.signInButton')}
              </Button>
            }      
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}