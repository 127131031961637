import { UserModel } from "../../../models/response/user_model";


export enum AuthType{
    Success,
    Fail,
    Pending
}
export enum LoginStep{
    first,
    second,
}

export interface AuthenticationState {
    loading: boolean,
    forgotYourPassword: boolean;
    loginStep: LoginStep;
    email:string;
    currentTenant:string;    
    loginWithPassword: boolean | undefined;
    
    user: UserModel | undefined, // for user object
    userToken: string | null, // for storing the JWT
    error: string | null,
    success: AuthType, 
}