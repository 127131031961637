import { Box, Chip, Divider, Typography, useTheme } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export interface ChooseOrganizationProps {
    onClick: () => void;
    title: string;
}

export const ChooseOrganization: React.FC<ChooseOrganizationProps> = (props: ChooseOrganizationProps) => {
    
    const theme = useTheme();
    
    return (
        <Box p={1}>
            <Chip
                onClick={props.onClick}
                label={props.title}
                variant="outlined"
                onDelete={props.onClick} 
                deleteIcon={<KeyboardArrowDownIcon />}
            />            
        </Box>
    )
}