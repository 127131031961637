import { CardMedia, Stack, Typography, useTheme } from "@mui/material"
import noCards from '../../assets/PlaceHolder.png';
import startComment from '../../assets/comment.png';

export enum ErrorType{
  card,
  comment,
}


export interface ErrorComponentProps{
    caption:string;
    image: ErrorType;
    width?: string;
    height?:string;
}
export const ErrorComponent = (props:ErrorComponentProps) => {
    const theme = useTheme();
    var image:string = noCards;
    
    switch (props.image) {
      case ErrorType.card:
        image = noCards;
        break;
      case ErrorType.comment:
        image = startComment;
        break;
    
      default:
        break;
    }

    return (
        <Stack p={2} justifyContent='center'  alignItems='center' >
            <CardMedia
              component="img"
              image={image}
              sx={{
                width: props.width || 400,
                height:props.height || 400,
                objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
              }}
            />
            <Typography variant='h6' color={theme.palette.textColor!.main} sx={{
            textAlign: 'center'
        }}>{props.caption}</Typography>
        </Stack>
    )
}