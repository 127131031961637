import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/api/authSlice';
import customerServiceSlice from '../features/customer_service/api/customer_service_slice';


const store = configureStore({
    reducer: {
      customerService: customerServiceSlice,
      auth: authReducer,
    },
  });

  export type RootState = ReturnType<typeof store.getState>;
  export type AppDispatch = typeof store.dispatch;
  export default store;