import { AttachmentModel } from "./attachment_model";


export enum TicketType{
    hint,
    issue,
    none,
}

export interface TicketModel{
    id: string;
    title:string;
    attachments?: AttachmentModel[];
    
    chatBalance: {
        hasNotification: boolean;
        notificationCounter: number;
    },
    
    user:{
        senderId:string;
        senderDisplayName: string;
        senderPhoto: string;
    }

    type: TicketType;
    isSolved: boolean;

    updatedAt: string;
    createdAt: string;
}