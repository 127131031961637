import React from 'react';
import { Box } from '@mui/material';
import logotype from '../../assets/logotype.png';


export interface HeaderProps {
    className?: string;
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {

    return (
        <Box 
        sx={{ backgroundColor:'rgba(247, 249, 252, 1)',  border: '1px solid rgba(231, 231, 231, 1)' }}
        display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} p={1}>
            <Box>
                <img src={logotype} width={'230px'}/>
            </Box>
        </Box>
    );
}